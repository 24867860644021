import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../LoungeCard/loungeCard.css';
import './communityCard.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { NO_COVER_IMG, NO_PROFILE } from '../../utils/routes';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import { Avatar, Button, Stack } from '@mui/material';
import CreateCommunity from '../createCommunity/CreateCommunity';
import JoinCommunity from '../joinCommunity/JoinCommunity';
import { useApiService } from '../../services/api-service';
import toast from "react-hot-toast";
import { CommunityContext } from '../../contexts/CommunityContext';

const CommunityCard = ({ searchQuery }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openJoinModal, setJoinModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const { communityInfo, setCommunityInfo, loading, loadCommunity } = useContext(CommunityContext);
  const apiService = useApiService();;

  // const fetchData = async () => {
  //   const MAX_RETRIES = 5;
  //   const RETRY_DELAY = 1000; // 1 second
  //   try {
  //     setLoading(true);
  //     const response = await apiService.community.membership();

  //     if (response.status === 200 || response.status === 201) {
  //       const data = Object.values(response.data || {});

  //       if (Object.keys(data).length === 0) {
  //         console.log('data is an empty object');
  //       } else {
  //         const fetchWithRetry = async (requestFn, maxRetries) => {
  //           for (let attempt = 0; attempt < maxRetries; attempt++) {
  //             try {
  //               const response = await requestFn();
  //               if (response.status === 200 || response.status === 201) {
  //                 const data = response.data;
  //                 if (Object.keys(data).length > 0) {
  //                   return data;
  //                 }
  //               }
  //             } catch (error) {
  //               console.error(`Attempt ${attempt + 1} failed:`, error);
  //               return null; // Exit the loop on error
  //             }
  //             await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
  //           }
  //           throw new Error('Max retries reached or error encountered');
  //         };
  //         const handleRequest = async (community, requestFn, key) => {
  //           try {
  //             const data = await fetchWithRetry(requestFn, MAX_RETRIES);
  //             if (data) {
  //               community[key] = data;
  //             } else {
  //               community.failed = true;
  //             }
  //           } catch (error) {
  //             console.error(`Error fetching ${key} for community ID ${community.id}`, error);
  //             community.failed = true;
  //           }
  //         };

  //         const createRequests = (communities, requestFn, key) =>
  //           communities.map(async (community) =>
  //             handleRequest(community, () => requestFn(community), key)
  //           );

  //         const commDetailsRequests = createRequests(
  //           data,
  //           (n) => apiService.community.info({ comm_uuid: n.comm_uuid }),
  //           'commDetails'
  //         );

  //         await Promise.allSettled([
  //           ...commDetailsRequests
  //         ]);

  //         const successfulRequests = data.filter((community) => !community.failed);
  //         localStorage.setItem('communityInfo', JSON.stringify(successfulRequests));
  //         setCommunityInfo([...successfulRequests]);
  //       }
  //     } else {
  //       console.error('Error fetching community:', response.statusText);
  //     }
  //   } catch (e) {
  //     if (e.response) {
  //       toast.error(e.response.data["code "]);
  //     } else {
  //       toast.error("Network Error");
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getPaymentChannels = async() => {
    try {
        const response = await apiService.payment.paymentChannels();
        console.log('response', response.data)
        localStorage.setItem('payment_methods', JSON.stringify(response.data));
        return response.data;
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
        }
    }
}

  useEffect(() => {
    getPaymentChannels();
    const storedCommunityInfo = JSON.parse(localStorage.getItem('communityInfo'));
    if (storedCommunityInfo) {
      setCommunityInfo(storedCommunityInfo);
    } else {
      loadCommunity();
    }
  }, []);

  useEffect(() => {
    const checkForUpdates = async () => {
      const response = await apiService.community.membership();
      if (response.status === 200 || response.status === 201) {
        const data = Object.values(response.data || {});
        const storedCommunityInfo = JSON.parse(localStorage.getItem('communityInfo'));
        if (storedCommunityInfo && data.length !== storedCommunityInfo.length) {
          loadCommunity();
        }
      }
    };

    checkForUpdates();
  }, []);

  let filteredCommunityData;
  if(!loading){
  filteredCommunityData = communityInfo.filter((community) => {
    const { commName, commDescr } = community.commDetails;
    if (commName && commDescr) {
      return (
        commName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        commDescr.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return false;
  });
}

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleJoinOpenModal = () => {
    setJoinModal(true);
  };

  const handleJoinCloseModal = () => {
    setJoinModal(false);
  };

  const mapCommTypeToText = (type) => {
    switch (type) {
      case 'DEMOCRATIZED_COMM':
        return 'DEMOCRATIZED';
      case 'ADMIN_MANAGED_COMM':
        return 'ADMIN MANAGED';
      default:
        return '';
    }
  };

  return (
    <>
    {loading ? (
      <>
      <CardSkeleton cards={8} />
      </>
    ) : (
      <>
      {filteredCommunityData.length === 0 ? (
        <>
        <div className='no-community'>
        <p style={{textAlign: 'center'}}>No records found of community</p>
        <Stack direction={'row'} spacing={2}>
        <button onClick={handleOpenModal} className='button create-or-join-btn'>Create</button>
        <button onClick={handleJoinOpenModal} className='button create-or-join-btn'>Join Community</button>
        </Stack>
      </div>
      {openModal &&
        <div className="modal-overlay">
          <CreateCommunity onCancel={handleCloseModal} />
        </div>
      }
      {openJoinModal &&
        <div className="modal-overlay">
          <JoinCommunity onCancel={handleJoinCloseModal} />
        </div>
      }
        </>
      ) : (
        <>
        {filteredCommunityData.map((community, index) => (
        <div className="lounge-card" key={index}>
          <div className="lounge-cover">
          <img
          src={
            community.commDetails.banner
              ? `${community.commDetails.banner}`
              : NO_COVER_IMG
          }
          alt="Lounge Cover"
        />

          </div>
          <div className="lounge-profile">
            <img src={community.commDetails.photoUrl || NO_PROFILE} alt="Lounge Profile" />
          </div>
          <div className="lounge-info">
            <h2 className="lounge-name">{community.commDetails.commName || <Skeleton />}</h2>
            <p className="lounge-description">{community.commDetails.commDescr || <Skeleton />}</p>
            <div className="lounge-stats">
              <span className="lounge-members">{mapCommTypeToText(community.commDetails.communityType)}</span>
              {/* <span onClick={seeCommInfo} className="lounge-posts">20 posts</span> */}
            </div>
          </div>
          <div className="lounge-cta">
            <Link to={`/community/${community.commDetails.commUuid ?? ''}`}><Button sx={{color: 'white'}} className="join-button button">Visit Community</Button></Link>
          </div>
        </div>
      ))}
        </>
      )}
      
      </>
    )}
    </>
  );
};


export default CommunityCard;
