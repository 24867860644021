import React from 'react'
import { useState } from 'react';
import './raiseMeKYC.css'
import { citiesData } from '../../Data/citiesData';
import { useEffect } from 'react';

const RaiseMeKYC = ({ onCancel, handleCreateKYC,
  firstName, setFirstName,
  lastName, setLastName,
  email, setEmail,
  location, setLocation,
  phoneNumber, setPhoneNumber,
  postalCode, setPostalCode,
  address, setAddress,
  isSubmitting
}) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [FormErrorMessage, setFormErrorMessage] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);


  const handleEmailChange = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setEmail(inputEmail);
    if (isValid) {
      setIsEmailValid(true);
      setEmailErrorMessage('');
    } else {
      setIsEmailValid(false);
      setEmailErrorMessage('Please enter a valid email address');
    }
    validateForm();
  };
  
  const handleFirstNameChange = (inputFirstName) => {
    const fn = inputFirstName
    setFirstName(fn);
    if (fn.length < 2) {
      setFirstNameError('First name must be at least 2 characters');
    } else {
      setFirstNameError('');
    }
    validateForm();
  };
  
  const handleLastNameChange = (inputLastName) => {
    const ln = inputLastName
    setLastName(ln);
    if (ln.length < 2) {
      setLastNameError('Last name must be at least 2 characters');
      
    } else {
      
      setLastNameError('');
    }
    validateForm();
  };
  
  const validateForm = () => {
    if(isEmailValid && firstName.length >= 2 && lastName.length >= 2 && isPhoneValid){
      setIsButtonDisabled(false)
      setFormErrorMessage('')
    } else{
      setIsButtonDisabled(true);
      setFormErrorMessage('One or more fields are incorrectly filled')
    } 
  };

  useEffect(() => {
    validateForm();
  }, [email, firstName, lastName, phoneNumber])
  

  const handlePhoneNumberChange = (inputPhoneNumber) => {
    // Remove non-digit characters from the input
    const digitsOnly = inputPhoneNumber.replace(/\D/g, '');
  
    // Check if the phone number starts with "+1" and has a length of 11 digits
    if (digitsOnly.startsWith('1') && digitsOnly.length === 11) {
      // Reformat the phone number to match the Canadian format: +12505550199
      const formattedPhoneNumber = `+${digitsOnly.slice(0, 1)} (${digitsOnly.slice(1, 4)}) ${digitsOnly.slice(4, 7)}-${digitsOnly.slice(7, 11)}`;
      setPhoneNumber(`+${formattedPhoneNumber.replace(/\D/g, '')}`);
      setIsPhoneValid(true);
      setErrorMessage('');
    } else {
      // If the input doesn't match the expected format, set an error message
      setPhoneNumber(inputPhoneNumber);
      setErrorMessage('Please enter a phone number with a Canadian country code (e.g., +12505550199)');
      setIsPhoneValid(false);
    }
  
    validateForm();
  };
  

  const handleNigeriaPhoneNumberChange = (inputPhoneNumber) => {
    // Check and format Nigeria phone numbers (+234XXXXXXXXXX)
    const digitsOnly = inputPhoneNumber.replace(/\D/g, '');
    if (digitsOnly.startsWith('234') && digitsOnly.length === 13) {
      // Valid Nigeria format, set the phone number
      setPhoneNumber(`+${digitsOnly}`);
      setIsButtonDisabled(true);
      setIsPhoneValid(true)
      setErrorMessage('');
    } else {
      // Invalid format, set an error or handle accordingly
      setPhoneNumber(inputPhoneNumber);
      setIsPhoneValid(false)
      setErrorMessage('Please enter a phone number with a Nigerian country code (e.g., +234XXXXXXXXXX)');
      setIsButtonDisabled(false)
    }
    validateForm();
  };
  

  // const renderCitiesDropdown = () => {
  //   return (
  //     <div className="kyc-form-group">
  //       <label htmlFor="city">City:</label>
  //       <select id="city" value={selectedCity} onChange={handleCityChange}>
  //         <option value="">Select City</option>
  //         {citiesData.map((city) => (
  //           <option key={city.code} value={city.name}>
  //             {city.name}
  //           </option>
  //         ))}
  //       </select>
  //     </div>
  //   );
  // };

  // const renderLGAsDropdown = () => {
  //   if (selectedCity) {
  //     const selectedCityData = citiesData.find((city) => city.name === selectedCity);
  //     if (selectedCityData) {
  //       return (
  //         <div className="kyc-form-group">
  //           <label htmlFor="lga">LGA:</label>
  //           <select id="lga" value={selectedLGA} onChange={handleLGAChange}>
  //             <option value="">Select LGA</option>
  //             {selectedCityData.lgas.map((lga) => (
  //               <option key={lga} value={lga}>
  //                 {lga}
  //               </option>
  //             ))}
  //           </select>
  //         </div>
  //       );
  //     }
  //   }
  //   return null;
  // };

  

  const renderFormFields = () => {
    if (location === 'Canada') {
      return (
        <>
        {/* <div className="kyc-form-group">
            <label htmlFor="account-name">Province:</label>
            <select id="province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}>
              <option value="">Select Province</option>
              <option value="Alberta">Alberta</option>
              <option value="British Columbia">British Columbia</option>
              <option value="Manitoba">Manitoba</option>
              <option value="New Brunswick">New Brunswick</option>
              <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
              <option value="Northwest Territories">Northwest Territories</option>
              <option value="Nova Scotia">Nova Scotia</option>
              <option value="Ontario">Ontario</option>
              <option value="Prince Edward Island">Prince Edward Island</option>
              <option value="Quebec">Quebec</option>
              <option value="Saskatchewan">Saskatchewan</option>
              <option value="Yukon">Yukon</option>
            </select>
          </div>
          <div className="kyc-form-group">
            <label htmlFor="account-name">City:</label>
            <input type="text" id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required />
          </div> */}
          {/* <label className='kyc-phone-label' htmlFor="phone-number">Phone Number:</label>
          <div className="phone-number-input">
            <div className="kyc-form-group">
            <select
              id="country-code"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option value="+1">+1</option>
              <option value="+234">+234</option>
            </select>
            </div>
            <div className="kyc-form-group kyc-phone">
            <input
              type="tel"
              id="phone-number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              placeholder="Enter your phone number"
            />
            </div>

          </div> */}
          {/* <div className="kyc-form-group">
            <label htmlFor="AddressLine">Address:</label>
            <textarea
              id="address"
              value={address}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 80) {
                  setAddress(inputValue);
                }
              }}
              placeholder="Enter your address"
              rows="2"
              maxLength="80" // Set the maximum character limit
            />
          </div>
          <div className="kyc-form-group">
            <label htmlFor="AddressLine">Address 2:</label>
            <textarea
                id="address2"
                value={address2}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 80) {
                    setAddress2(inputValue);
                  }
                }}
                placeholder="Enter your address"
                rows="2"
                maxLength="80" // Set the maximum character limit
              />
          </div>
          <div className="kyc-form-group">
            <label htmlFor="account-name">Postal Code:</label>
            <input type="text" id="start-date" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />
          </div> */}
        </>
      );
    } else if (location === 'Nigeria') {
      return (
        <>
        {/* <div className="kyc-form-group">
          <label htmlFor="account-name">Phone Number:</label>
            <input type="text" id="account-name" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
          </div>
          {renderCitiesDropdown()}
          {renderLGAsDropdown()}
          <div className="kyc-form-group">
            <label htmlFor="AddressLine">Street Address:</label>
            <textarea
              id="address"
              value={address}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 80) {
                  setAddress(inputValue);
                }
              }}
              placeholder="Enter your address"
              rows="2"
              maxLength="80" // Set the maximum character limit
            />

          </div>
          <div className="kyc-form-group">
            <label htmlFor="AddressLine">Street Address 2:</label>
            <textarea
                id="address2"
                value={address2}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 80) {
                    setAddress2(inputValue);
                  }
                }}
                placeholder="Enter your address"
                rows="2"
                maxLength="80" // Set the maximum character limit
              />
              </div>
          <h3>Next of Kin</h3>
          <div className="kyc-dual-form">
          <div className="kyc-form-group">
          <label htmlFor="account-name">First Name:</label>
            <input type="text" id="account-name" value={nextOfKinFirstName} onChange={(e) => setNextOfKinFirstName(e.target.value)} required />
          </div>
          <div className="kyc-form-group">
            <label htmlFor="account-name">Last Name:</label>
            <input type="text" id="email" value={nextOfKinLastName} onChange={(e) => setNextOfKinLastName(e.target.value)} required />
          </div>
          </div>
          <div className="kyc-dual-form">
          <div className="kyc-form-group">
            <label htmlFor="account-name">Gender:</label>
            <select value={nextOfKinGender} onChange={(e) => setNextOfKinGender(e.target.value)}>
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <div className="kyc-form-group">
            <label htmlFor="account-name">E-mail:</label>
            <input type="email" id="next-of-kin-email" value={nextOfKinEmail} onChange={(e) => setNextOfKinEmail(e.target.value)} required />
          </div>
          </div>
          <div className="kyc-dual-form">
          <div className="kyc-form-group">
          <label htmlFor="account-name">Phone Number:</label>
            <input type="tel" id="account-name" value={nextOfKinPhone} onChange={(e) => setNextOfKinPhone(e.target.value)} required />
          </div>
          <div className="kyc-form-group">
            <label htmlFor="account-name">Relationship:</label>
            <select value={nextOfKinRelationship} onChange={(e) => setNextOfKinRelationship(e.target.value)}>
              <option value="">Select relationship</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
            </select>
          </div>
          </div>
          <div className="kyc-form-group">
            <label htmlFor="AddressLine">Address:</label>
            <textarea
                id="address"
                value={nextOfKinAddress}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 80) {
                    setNextOfKinAddress(inputValue);
                  }
                }}
                required
                placeholder="Enter your address"
                rows="2"
                maxLength="80" // Set the maximum character limit
              />
          </div> */}
          {/* Add other Nigeria specific form fields here */}
        </>
      );
    }
    // Default case when location is not selected or unknown
    return null;
  };

  return (
    <>
      <h2 className="onboarding-text fill-kyc-header">Fill KYC</h2>
      <p className='kyc-desc'>Fill the following KYC(know your customer) fields</p>
      <form style={{width: '100%'}} onSubmit={handleCreateKYC}>
        <div className="form-top">
          <div className="kyc-dual-form">
          <div className="kyc-form-group">
          <label htmlFor="account-name">First Name:</label>
          <input
            type="text"
            id="account-name"
            placeholder="Joe"
            value={firstName}
            onChange={(e) => handleFirstNameChange(e.target.value)}
            required
          />
          {firstNameError && (
            <p style={{ color: 'red', fontSize: 'small' }}>{firstNameError}</p>
          )}
        </div>
          <div className="kyc-form-group">
          <label htmlFor="account-name">Last Name:</label>
            <input type="text" id="account-name" placeholder='Doe' value={lastName} onChange={(e) => handleLastNameChange(e.target.value)} required />
            <p style={{ color: 'red', fontSize: 'small' }}>
              {lastNameError}
            </p>
          </div>
          </div>
          <div className="kyc-dual-form">
          <div className="kyc-form-group">
            <label htmlFor="account-name">Email:</label>
            <input type="email" id="email" placeholder='joe@example.com' value={email} onChange={(e) => handleEmailChange(e.target.value)} required />
            {!isEmailValid && (
            <p style={{ color: 'red', fontSize: 'small' }}>
              {emailErrorMessage}
            </p>
          )}
          </div>
          <div className="kyc-form-group kyc-phone">
          <label htmlFor="account-name">Phone Number:</label>
          {location === 'Canada' ? (
             <input
              type="tel"
              id="phone-number"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              placeholder="+12505550199"
              required
            />
            ) : (
              <input
              type="tel"
              id="phone-number"
              value={phoneNumber}
              onChange={(e) => handleNigeriaPhoneNumberChange(e.target.value)}
              placeholder="+2348138455576"
              required
            />
            )}
            {errorMessage && <p style={{ color: 'red', fontSize: 'small' }}>{errorMessage}</p>}
            </div>
          </div>

          <div className="kyc-dual-form">
          <div className="kyc-form-group">
            <label htmlFor="account-name">Address Street:</label>
            {location === 'Canada' ? (
            <input type="text" id="address" placeholder='54658 Barter Street' value={address} onChange={(e) => setAddress(e.target.value)} required />
            ) : (
              <input type="text" id="address" placeholder='8, My Street, Ilassan Lekki' value={address} onChange={(e) => setAddress(e.target.value)} required />
              )}
          </div>
          <div className="kyc-form-group">
          <label htmlFor="account-name">Postal Code:</label>
          {location === 'Canada' ? (
            <input type="text" id="postal-code" placeholder='M5V 3L9' value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />
            ) : (
              <input type="text" id="postal-code" placeholder='102102' value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />
              )}
          </div>
          </div>
          
        </div>
        <div className="form-bottom">
        {FormErrorMessage && (
          <span className="error-text">{FormErrorMessage}</span>
        )}
          <div className="form-actions-kyc">
          <button className="button" type="submit" disabled={isButtonDisabled}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <button className="button" type="button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RaiseMeKYC;