import { Avatar, Box, Button, Checkbox, Divider, FormControlLabel, Radio, Stack, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NO_COVER_IMG, formatAmountWithCommas } from '../../utils/routes'
import { Cancel, CreditCardOutlined, Email, MonetizationOn } from '@mui/icons-material'
import './donate.css'
import { useApiService } from '../../services/api-service'
import toast from "react-hot-toast";
import Successful from "../../lottiefiles/Successful.json"
import Lottie from 'lottie-react'
import { useProfileService } from '../../services/profile-service'

const Donate = ({loungeData, onCancel, creatorDetails, getStatements}) => {
    
    const paymentMethods = JSON.parse(localStorage.getItem('payment_methods'));
    console.log('payments, ', paymentMethods)
    const [paymentMethod, setPaymentMethod] = useState();
    const [amount, setAmount] = useState('');
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const apiService = useApiService();
    const [checked, setChecked] = React.useState(false);
    const [step, setStep] = useState(1);
    const [isDonating, setIsDonating] = useState(false);
    const profile = useProfileService();

    // Validation functions
    const isValidFirstName = (name) => /^[a-zA-Z]+$/.test(name) && name.length >= 2;
    const isValidLastName = (name) => /^[a-zA-Z]+$/.test(name) && name.length >= 2;
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleAmount = (e) => {
        // Remove non-numeric characters from the input value
        const formattedValue = e.target.value.replace(/[^\d]/g, '');
        // Update the state with the formatted value
        setAmount(formattedValue);
    }

    const handleChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleFirstName = (e) => {
        const name = e.target.value;
            setFirstName(name);
    }

    const handleLastName = (e) => {
        const name = e.target.value;
            setLastName(name);
    }

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
      };
    
    const makeDonation = async (e) => {
        e.preventDefault(); // Corrected to e.preventDefault();
        try {
            setIsDonating(true);
            const paymentChannelId = paymentMethods.find(method => method.payment_method === paymentMethod)?.id || 0; // Get the selected method's ID
            const requestBody = {
                amount: amount,
                email: email,
                first_name: firstName,
                last_name: lastName,
                entity_id: String(loungeData.id),
                entity_name: loungeData.name,
                payment_channel_id: paymentChannelId,
                transaction_mode: 'DEPOSIT',
                payment_type: 'AidloungeDonation',
                user_id: profile.profile.userId || -1
            }
            const response = await apiService.payment.processPayment(requestBody)
            if(response.status === 200 || response.status === 201){
                setStep(step + 1);
                getStatements();
            }
        } catch (e) {
            if(e.response){
                toast.error(e.response.data["code "]);
            }
        } finally{
            setIsDonating(false);
        }
    }
  return (
    <Box className="raiseme-position-modal">
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            {step === 1 && (
                <>
                    <Cancel sx={{cursor: 'pointer', marginLeft: 'auto'}} onClick={onCancel}/>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Avatar
                        sx={{
                            width: '60px',
                            height: '60px',
                        }}
                        src={NO_COVER_IMG}/>
                        <Stack direction={'column'} sx={{display: 'flex', alignItems: 'flex-start'}}>
                            <p style={{ fontSize: '17px', display: 'inline', textAlign: 'left' }}>
                            You're supporting <b>{loungeData.name}</b>
                            </p>
                            <p style={{display: 'inline', textAlign: 'left'}}>Your donation would benefit <b>{creatorDetails.firstName} {creatorDetails.lastName}</b></p>
                        </Stack>
                    </Stack>
                    <form onSubmit={makeDonation}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <h3>Enter your donation</h3>
                        <Box sx={{marginBottom: '10px'}} className='donate-input-container'>
                            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <MonetizationOn/>
                                <h3>CAD</h3>
                            </Stack>
                            <Stack direction={'row'} style={{width: '100%'}}>
                                <input style={{width: '100%'}} className='donate-input'
                                type="text" inputMode='numeric' autoComplete='off'
                                maxLength={6} autoFocus onChange={handleAmount}
                                value={formatAmountWithCommas(amount)}
                                required
                                />
                                <h3 className='donate-h3'>.00</h3>
                            </Stack>
                        </Box>
                        <h3>Payment Method</h3>
                        <Box className='donate-input-container'>
                            <Stack
                            className='donate-payment-method'
                            direction={'column'}
                            >
                                {paymentMethods && paymentMethods.length > 0 ? (
                                    paymentMethods.map((method) => (
                                    <>
                                        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                        <Radio
                                            value={method.payment_method}
                                            checked={paymentMethod === 'INTERAC'}
                                            onChange={handleChange}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                            />
                                        <Email/>
                                        <h4>Pay with {method.payment_method}</h4>
                                        </Box>
                                    </>
                                    ))
                                ) : (
                                    <div>No payment methods available</div>
                                )}
                                
                                {paymentMethod === 'INTERAC' && (
                                    <>
                                    <Stack direction={'row'} spacing={1} marginBottom={2} width={'100%'}>
                                        <input className='donate-user'
                                        type='text' placeholder='First Name'
                                        onChange={handleFirstName}
                                        value={firstName}
                                        required
                                        />
                                        <input className='donate-user'
                                        type='text' placeholder='Last Name'
                                        onChange={handleLastName}
                                        value={lastName}
                                        required
                                        />
                                    </Stack>
                                    <input className='donate-email'
                                    type='email' placeholder='Interac Email'
                                    onChange={handleEmail}
                                    value={email}
                                    required
                                    />
                                    </>
                                )}
                            </Stack>
                        </Box>
                        {/* <FormControlLabel control={<Checkbox />} label="By choosing the payment method above, you agree to the GoFundMe Terms of Service and acknowledge the Privacy Notice.
                        Learn more about pricing and fees." /> */}
                        <FormControlLabel control={<Checkbox />}
                        checked={checked}
                        onChange={handleCheckboxChange}
                        label="Don't display my name publicly on the fundraiser" />

                    </Box>
                    <Button disabled={!paymentMethod || isDonating} className='button'
                    sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                    type='submit'
                    >{isDonating ? 'Donating' : 'Donate Now'}</Button>
                    </form>
                </>
            )}
            {step === 2 && (
                <>
                    <Cancel sx={{cursor: 'pointer', marginLeft: 'auto'}} onClick={onCancel}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make donation <b>{loungeData.name}</b> aid lounge</h2>
                        <p>Check your email to process your request</p>
                        <Lottie animationData={Successful}/>
                    </Box>
                </>
            )}
        </Box>
    </Box>
  )
}

export default Donate