import { Cancel, CreditCardOutlined, MonetizationOn, WalletRounded } from '@mui/icons-material';
import { Box, Button, Divider, Radio, Skeleton, Stack, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LoadingPopup, formatAmountWithCommas } from '../../utils/routes';
import { useApiService } from '../../services/api-service';
import AccountsDropdown from '../RaiseMeAccount/AccountsDropdown';
import Lottie from 'lottie-react';
import Successful from "../../lottiefiles/Successful.json"
import './withdrawal.css'
import toast from 'react-hot-toast';
import { useProfileService } from '../../services/profile-service';

const WithdrawAid = ({loungeData, onCancel, id}) => {
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [amount, setAmount] = useState('');
  const apiService = useApiService();
  const [existingAccounts, setExistingAccounts] = useState([]);
  const [isFetchingAccount, setIsFetchingAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [location, setLocation] = useState('Canada');
  const [step, setStep] = useState(1);
  const profile = useProfileService();
  
  const [intAmount, setIntAmount] = useState();
  const [votes, setVotes] = useState();
  const [accountName, setAccountName] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [routingNumber, setRoutingNumber] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [bankBranch, setBankBranch] = useState(null);
    const [interacEmail, setInteracEmail] = useState(null);
    const [interacPhone, setInteracPhone] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [accountType, setAccountType] = useState('');
    const [verifyUrl, setVerifyUrl] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [currency, setCurrency] = useState('');
    const [bvn, setBvn] = useState(null);
    const [withdrawalBalance, setWithdrawalBalance] = useState();
    const [loadingBalance, setLoadingBalance] = useState(false);
    const [kycData, setKycData] = useState();
  const handleAmount = (e) => {
    // Remove non-numeric characters from the input value
    const formattedValue = e.target.value.replace(/[^\d]/g, '');
    // Update the state with the formatted value
    setAmount(formattedValue);
    setIntAmount(formattedValue);
}

const getKYC = async () => {
  try {
    const response = await apiService.raiseme.getKYCByLocation('Canada');
    if(response.status === 200 || response.status === 201){
      setKycData(response.data);
    }
  } catch (e) {
    if(e.response){
      if(e.response.status !== 404){
        toast.error(e.response.data["code "]);
      }
    }else{
      toast.error('Network Error');
    }
  }
}

const getWithdrawalBalance = async () => {
  try {
    const balance = await apiService.payment.entityBalance({
      entity_id: id,
      payment_type: 'AidloungeDonation'
    })
    setWithdrawalBalance(balance.data.availableBalance)
  } catch (e) {
    if(e.response){
      if(e.response.data["code "]){
        setWithdrawalBalance(0)
      }
    }
  }
}

const handleSelectAccount = async (accountId) => {
  if (accountId) {
    if (accountId.location !== location) {
      toast.error("Account location doesn't match raiseMe location.");
    } else {
      // Set state values using the selected account's properties
      setAccountName(accountId.account_name);
      setAccountNumber(accountId.account_number);
      setAccountType(accountId.account_type);
      setBvn(accountId.bvn);
      setCurrency(accountId.currency);
      setBankName(accountId.financial_institution_name);
      setBankBranch(accountId.financial_institution_branch);
      setInteracEmail(accountId.interac_email);
      setInteracPhone(accountId.interac_phone_number);
      setLocation(accountId.location);
      setRoutingNumber(accountId.routing_transit_number);
      setAccountId(accountId.id);
      setAccountStatus(accountId.partner_status);
      setVerifyUrl(accountId.verify_url);
    }
} else {
  setAccountId(null)
}
};

const fetchAccounts = async () => {
  try {
    const response = await apiService.raiseme.getAccount();
    const responseData = response.data;
      setExistingAccounts(responseData); // Set the existing accounts data
    const voteresponse = await apiService.aidlounge.votes({
      user_id: loungeData.creatorId,
      aidlounge_id: loungeData.id
    })
    setVotes(voteresponse.data);
  } catch (e) {
    if(e.response){
      if(e.response.status !== 404){
        toast.error(e.response.data["code "]);
      }
    } else {
      toast.error('Network Error');
  }
  } 
};

const fetchData = async () => {
  try {
    setIsFetchingAccount(true);
    await fetchAccounts();
    await getWithdrawalBalance();
    await getKYC();
  } catch (e) {
    
  } finally{
    setIsFetchingAccount(false);
  }
}


useEffect(() => {
  fetchData();
}, []);

let filteredAccounts = [];

if(existingAccounts){
  filteredAccounts = existingAccounts.filter(account => account.location === 'Canada');
}

const makeWithdrawal = async (e) => {
  e.preventDefault();
  setIsWithdrawing(true);
  try {
    if(selectedAccount.partner_status !== 'ACCOUNT_VERIFICATION_DONE'){
      toast.error('Select verified account or verify account');
    } else {
      const customerNumber = parseInt(kycData.customerNumber, 10);
      const bankNumber = parseInt(selectedAccount.financial_institution_branch, 10)
      const intAmount = parseFloat(amount);
    const requestBody = {
      customer_number: customerNumber,
      amount: intAmount,
      transit_number: selectedAccount.routing_transit_number,
      account_number: `${selectedAccount.account_number}`,
      bank_number: selectedAccount.financial_institution_branch,
      entity_id: String(loungeData.id),
      entity_name: loungeData.name,
      user_id: profile.profile.userId,
      payment_channel_id: 1, // Use the selected method's ID
       transaction_mode: 'WITHDRAW',
      payment_type: 'AidloungeDonation',
    }
    const response = await apiService.payment.processPayment(requestBody)
    // const response = await apiService.aidlounge.makeWithdrawal(requestBody);
    if (response.status === 200 || response.status === 201) {
      setAmount('');
      toast.success('Withdrawal Initiated Successfully');
      setStep(step + 1)
    }
  }
  } catch (e) {
    
  } finally{
    setIsWithdrawing(false);
  }
}

const requestWithdrawal = async (e) => {
  e.preventDefault();
  setIsWithdrawing(true);
  try {
    const requestBody = {
      sender_id: loungeData.creatorId,
      notification_type: "aidloungeWithdrawal",
      comm_uuid: loungeData.communityId,
      aidlounge_id: loungeData.id,
      delivery_type: 2
    }
    const response = await apiService.notification.push(requestBody);
    if (response.status === 201 || response.status === 200) {
      toast.success('Withdrawal Requested Successfully');
      setAmount('')
      setStep(step + 1)
    }
  } catch (e) {
    
  } finally{
    setIsWithdrawing(false)
  }
}
let validVote = null;
if(votes){
  validVote = votes.endorsementCount>=votes.requiredEndorsement;
}
  return (
    <Box className="raiseme-position-modal">
      {loungeData.aidLoungeType === 'INDIVIDUAL' ? (
        <>
        {isFetchingAccount ? (
          <>
          {LoadingPopup()}
          </>
        ) : (
          <>
          {step === 1 && (
                <>
                <Cancel sx={{cursor: 'pointer', marginLeft: 'auto'}} onClick={onCancel}/>
                <form onSubmit={makeWithdrawal}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <WalletRounded className='big-icon' />
                        {loadingBalance ? (
                            <Skeleton />
                          ) : (
                            <>
                            <h3>Your Balance</h3>
                            <h2 className='blackText withdrawal-balance'>${withdrawalBalance}.00</h2>
                            </>
                          )}
                      </Box>
                        <AccountsDropdown filteredAccounts={filteredAccounts} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} handleSelectAccount={handleSelectAccount}/>
                        <h3>Enter your withdrawal amount</h3>
                        <Box sx={{marginBottom: '10px'}} className='donate-input-container'>
                            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <MonetizationOn/>
                                <h3>CAD</h3>
                            </Stack>
                            <Stack direction={'row'} style={{width: '100%'}}>
                                <input style={{width: '100%'}} className='donate-input'
                                type="text" inputMode='numeric' autoComplete='off'
                                maxLength={6} autoFocus onChange={handleAmount}
                                value={formatAmountWithCommas(amount)}
                                required
                                />
                                <h3 className='donate-h3'>.00</h3>
                            </Stack>
                        </Box>

                    </Box>
                    <Button className='button'
                    disabled={!selectedAccount || intAmount > withdrawalBalance || amount === '' || intAmount <= 0}
                    sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                    type='submit'
                    >{isWithdrawing ? 'Withdrawing' : 'Withdraw'}</Button>
                    </form>
                    </>
            )}
            {step === 2 && (
                <>
                    <Cancel sx={{cursor: 'pointer', marginLeft: 'auto'}} onClick={onCancel}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make withdrawal in <b>{loungeData.name}</b> aid lounge</h2>
                        <p>Check your email to process your request</p>
                        <Lottie animationData={Successful}/>
                    </Box>
                </>
            )}
          </>
        )}
        
        </>
      ): (
        <>
        {isFetchingAccount ? (
          <>
          {LoadingPopup()}
          </>
        ) : (
          <>
          {step === 1 && (
                <>
                <Cancel sx={{cursor: 'pointer', marginLeft: 'auto'}} onClick={onCancel}/>
                <form onSubmit={validVote ? makeWithdrawal : requestWithdrawal}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <WalletRounded className='big-icon' />
                        {!votes && (
                          <>
                          <h3>Request Withdrawal</h3>
                          <p style={{marginBottom: '5px'}}>When you request a withdrawal, a request gets sent to all the members of the community and when accepted you can withdraw</p>
                          </>
                        )}
                        {votes && (
                          <>
                          {loadingBalance ? (
                            <Skeleton />
                          ) : (
                            <>
                            <h3>Your Balance</h3>
                            <h2 className='blackText withdrawal-balance'>${withdrawalBalance}.00</h2>
                            </>
                          )}
                          </>
                        )}
                        {votes && !validVote && (
                          <>
                          <Stack direction={'column'} spacing={1} width={'100%'}>
                          <progress className='aid-lounge-progress' value={votes.endorsementCount} max={votes.requiredEndorsement} />
                          <p>{votes.requiredEndorsement - votes.endorsementCount} vote(s) left</p>
                          </Stack>
                          </>
                        )}
                      </Box>
                      {votes && (
                        <>
                        <AccountsDropdown filteredAccounts={filteredAccounts} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} handleSelectAccount={handleSelectAccount}/>
                        <h3>Enter your withdrawal amount</h3>
                        <Box sx={{marginBottom: '10px'}} className='donate-input-container'>
                            <Stack sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <MonetizationOn/>
                                <h3>CAD</h3>
                            </Stack>
                            <Stack direction={'row'} style={{width: '100%'}}>
                                <input style={{width: '100%'}} className='donate-input'
                                type="text" inputMode='numeric' autoComplete='off'
                                maxLength={6} autoFocus onChange={handleAmount}
                                value={formatAmountWithCommas(amount)}
                                required
                                />
                                <h3 className='donate-h3'>.00</h3>
                            </Stack>
                        </Box>
                        </>
                        )}

                    </Box>
                    {votes ? (
                      <>
                      <Button className='button'
                    disabled={!selectedAccount || intAmount > withdrawalBalance || amount === '' || validVote === false || intAmount <= 0}
                    sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                    type='submit'
                    >{isWithdrawing ? 'Withdrawing' : 'Withdraw'}</Button>
                      </>
                    ) : (
                      <>
                      <Button className='button'
                    disabled={validVote === false}
                    sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}
                    type='submit'
                    >{isWithdrawing ? 'Sending Request' : 'Request Withdrawal'}</Button>
                      </>
                    )}
                    </form>
                    </>
            )}
            {step === 2 && (
                <>
                    <Cancel sx={{cursor: 'pointer', marginLeft: 'auto'}} onClick={onCancel}/>
                    {validVote ? (
                      <>
                      <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make withdrawal in <b>{loungeData.name}</b> aid lounge</h2>
                        <p>Check your email to process your request</p>
                        <Lottie animationData={Successful}/>
                    </Box>
                      </>
                    ) : (
                      <>
                      <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <h2 style={{textAlign: 'center', display: 'inline'}} className='blackText'>You have sucessfully requested to make withdrawal in <b>{loungeData.name}</b> aid lounge</h2>
                        <p>You need the community to endorse your withdrawal</p>
                        <Lottie animationData={Successful}/>
                    </Box>
                      </>
                    )}
                </>
            )}
          </>
        )}
        </>
      )}
    
    </Box>
  )
}

export default WithdrawAid