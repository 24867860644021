import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/header/Header'
import UserProfile from '../../components/userProfile/UserProfile'
import {
  ExitToApp,
  Info,
  MonetizationOn,
  MoreVert,
  Person,
  PersonAddAlt,
  Settings,
  Weekend
} from '@mui/icons-material'
import { useApiService } from '../../services/api-service'
import PostShare from '../../components/postShare/PostShare'
import CommunityPosts from '../../components/posts/CommunityPosts'
import AddMemberToCommunity from '../../components/AddMemberToCommunity/AddMemberToCommunity'
import '../Community/community.css'
import '../OnBoarding/onboarding.css'
import { useProfileService } from '../../services/profile-service'
import { LoadingFullScreen, NO_COVER_IMG, capitalizeFirstLetter } from '../../utils/routes'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import toast from "react-hot-toast";
import CommunityInfo from '../../components/CommunityInfo/CommunityInfo'
import CreateLounge from '../../components/CreateLounge/CreateLounge'
import CommunityLounges from '../../components/CommunityLounges/CommunityLounges'
import { Fade, Modal, Backdrop, Box, Menu, MenuItem, Tooltip, Chip, Avatar, AvatarGroup, Stack, } from '@mui/material';
import EditCommunity from '../../components/EditCommunity/EditCommunity'
import CommunityMembers from '../../components/CommunityMembers/CommunityMembers'
import AidLoungesList from '../../components/AidLoungesList/AidLoungesList'
import ChitFundsList from '../../components/ChitFunds/ChitFundsList'
import RaiseMes from '../../components/RaiseMes/RaiseMes'
import CommunityWallet from '../../components/Community/CommunityWallet'
import ErrorComponent from '../../components/errors/ErrorComponent'
import Cookies from 'js-cookie';
import ConfirmRequest from '../../utils/ConfirmRequest'
import { CommunityContext } from '../../contexts/CommunityContext'
import InviteUser from '../../components/InviteUser/InviteUser'

const CommunityDetails = () => {
  const { uuid } = useParams() // Access the UUID parameter from the URL
  const [community, setCommunity] = useState(null)
  const apiService = useApiService();
  const profile = useProfileService();
  const { loadCommunity } = useContext(CommunityContext);
  const [showInviteUser, setShowInviteUser] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [loungeOpenModal, setLoungeOpenModal] = useState(false)
  const [showMembers, setShowMembers] = useState(false);
  const [showCommunityInfo, setShowCommunityInfo] = React.useState(false);
  const [showLounges, setShowLounges] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const handleOpenWallet = () => setShowWallet(true);
  const handleCloseWallet = () => setShowWallet(false);
  const [showCommunityLounges, setShowCommunityLounges] = useState(false);
  const [members, setMembers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchData();
  }
  const [openChit, setOpenChit] = React.useState(false);
  const handleOpenChit = () => setOpenChit(true);
  const handleCloseChit = () => setOpenChit(false);
  const [loadingCommInfo, setLoadingCommInfo] = useState(true);
  const [chitFunds, setChitFunds] = useState([]);
  const message = 'Error Loading Community';
  const encryptedToken = Cookies.get('encryptedToken');
  const history = useHistory();
  // options menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //open confirm request
  const [isExiting, setIsExiting] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenLoungeModal = () => {
    setLoungeOpenModal(true);
  }

  const handleCloseLoungeModal = () => {
    setLoungeOpenModal(false)
  }


  const handleInviteUser = () => {
    setShowInviteUser(true) // Show the CreateCommunity component
  }

  const handleCancelInviteUser = () => {
    setShowInviteUser(false) // Hide the CreateCommunity component
  }

  const handleShowCommunityInfo = () => {
    setShowCommunityInfo(true);
  };

  const handleCloseCommunityInfo = () => {
    setShowCommunityInfo(false);
  };

  const handleShowMembers = () => {
    setShowMembers(true);
  }

  const handleCloseMembers = () => {
    setShowMembers(false);
  }

  const handleShowLounges = () => {
    setShowLounges(true);
  }

  const handleCloseLounges = () => {
    setShowLounges(false);
  }

  const handleShowCommunityLounges = () => {
    setShowCommunityLounges(true);
  }

  const handleCloseCommunityLounges = () => {
    setShowCommunityLounges(false);
  }

  const fetchData = async () => {
    const MAX_RETRIES = 5;
    const RETRY_DELAY = 1000; // 1 second
    try {
      setLoadingCommInfo(true);
      const [communityResponse, membersResponse] = await Promise.all([
        apiService.community.info({ comm_uuid: uuid }),
        apiService.community.getCommunityMembers({ commUuid: uuid })
      ]);

      // Handle community info response
      if (communityResponse.status === 200) {
        const data = Object.values(communityResponse.data || {});
        if (Object.keys(data).length === 0) {
          window.location.reload();
        } else {
          setCommunity(communityResponse.data);
        }
      }

      // Handle community members response
      if (membersResponse.status === 200 || membersResponse.status === 201) {
        const data = Object.values(membersResponse.data || {});
        // Check if data is an empty object
        if (Object.keys(data).length === 0) {
          console.log('data is an empty object');
        } else {
          const fetchWithRetry = async (requestFn, maxRetries) => {
            for (let attempt = 0; attempt < maxRetries; attempt++) {
              try {
                const response = await requestFn();
                if (response.status === 200 || response.status === 201) {
                  const data = response.data;
                  if (Object.keys(data).length > 0) {
                    return data;
                  }
                }
              } catch (error) {
                console.error(`Attempt ${attempt + 1} failed:`, error);
                return null; // Exit the loop on error
              }
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            }
            throw new Error('Max retries reached or error encountered');
          };

          const handleRequest = async (user, requestFn, key) => {
            try {
              const data = await fetchWithRetry(requestFn, MAX_RETRIES);
              if (data) {
                user[key] = data;
              } else {
                user.failed = true;
              }
            } catch (error) {
              console.error(`Error fetching ${key} for user ID ${user.id}`, error);
              user.failed = true;
            }
          };
  
          // Create requests for each notification type
          const createRequests = (users, requestFn, key) =>
            users.map(async (user) =>
              handleRequest(user, () => requestFn(user), key)
            );
        
          const userDetailsRequests = createRequests(
            data,
            (n) => apiService.profile.profileDetails({ user_id: n.user_id }),
            'userDetails'
          );

          await Promise.allSettled([
            ...userDetailsRequests
          ]);

          const successfulRequests = data.filter((user) => !user.failed);
          setMembers([...successfulRequests])
        }
      } else {
        console.error('Error fetching community members:', membersResponse.statusText);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      }else{
        toast.error("Network Error")
      }
    } finally {
      setLoadingCommInfo(false);
    }
  };

  const handleMenuItemClick = async (action) => {
    // Implement actions based on the selected menu item (e.g., delete or make admin)
    if (action === 'leave') {
      handleOpenConfirm();
      handleMenuClose();
    }
  };

  const handleExitCommunity = async () => {
    try {
      setIsExiting(true);
      await toast.promise(
        apiService.community.leaveCommunity({
          comm_uuid: uuid
        }),
        {
          loading: 'Exiting Community',
          success: 'You have successfully exited the community.',
          error: 'Error exiting the community.',
        }
      )
      loadCommunity();
      history.push('/communities')
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      }else{
        toast.error("Network Error")
      }
    }
  }

  const getPaymentChannels = async() => {
    try {
        const response = await apiService.payment.paymentChannels();
        console.log('response', response.data)
        localStorage.setItem('payment_methods', JSON.stringify(response.data));
        return response.data;
    } catch (e) {
        if(e.response){
            toast.error(e.response.data["code "]);
        }
    }
}

  useEffect(() => {
    if(encryptedToken){
      return history.push(`/notification/${encryptedToken}`)
    }
    fetchData();
    getPaymentChannels();
  }, [uuid]);

  let isAdminComm;
  let isDemoComm;
  let walletStatus;
  let isUserAdmin;
  if(community){
    isAdminComm = community.communityType === "ADMIN_MANAGED_COMM";
    isDemoComm = community.communityType === "DEMOCRATIZED_COMM";
    walletStatus = community.walletStatus;
  }
  if(profile.profile){
    if(members && profile.profile.userId){
      let loggedInUserId = profile.profile.userId;
      let loggedInUserDetails = members.find(member => member.userDetails.userId === loggedInUserId);
      if(loggedInUserDetails){
      isUserAdmin = loggedInUserDetails.role === 'admin';
      }
    }
  }

  const mapCommTypeToText = (type) => {
    switch (type) {
      case 'DEMOCRATIZED_COMM':
        return 'DEMOCRATIZED';
      case 'ADMIN_MANAGED_COMM':
        return 'ADMIN MANAGED';
      default:
        return '';
    }
  };

  return (
    <>
      {loadingCommInfo ? (
        <>
        {LoadingFullScreen()}
        </>
      ) : (
        <>
          <Header uuid={uuid} />
          <div className='community-details-wrapper'>
            <div className='community-details-sidebar'>
              <UserProfile />
              {/* <MembersCard />
              <EventCard /> */}
            </div>
            {community && community.commName && community.commDescr && community.communityType
            && members ? (
              <>
              <div className='community-details-container'>
              <div className='profile-header-cover'>
                <div className='bannerContanier'>
                  <img
                    src={community.banner || NO_COVER_IMG}
                    alt=''
                  />
                </div>
                <div className='profile-header-info'>
                  <div className='user-short-description'>
                    {/* <img className='profile-img' src="img/calgary.png" alt="" /> */}
                    <Avatar className='profile-img' alt={community.commName}>
                      {capitalizeFirstLetter(community.commName)}
                    </Avatar>
                    <div className='community-description'>
                      <h2>{community.commName}</h2>
                      <p>{community.commDescr}</p>
                      <Chip icon={<Person/>} label={mapCommTypeToText(community.communityType)} size='small' sx={{backgroundColor: "light.main", marginTop: '5px', fontSize: '.675rem', width: 'fit-content'}}/>
                      <Stack onClick={handleShowCommunityInfo} sx={{cursor: 'pointer'}} direction={'row'} spacing={1} alignItems={'center'} marginTop={'5px'}>
                      <AvatarGroup max={4}
                      sx={{
                        '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 10 },
                      }}
                      >
                        {members.map((member) => (
                          <Avatar sx={{width: '24px', height: '24px'}} src={member.userDetails.photoUrl} key={member.userDetails.userId} alt={member.userDetails.firstName +'' + member.userDetails.lastName}>
                            {capitalizeFirstLetter(member.userDetails.firstName) + capitalizeFirstLetter(member.userDetails.lastName[0])}
                          </Avatar>
                        ))}
                      </AvatarGroup>
                      <p style={{color: 'white'}}>{members.length} {members.length <= 1 ? 'Member' : 'Members'}</p>
                      </Stack>
                    </div>
                  </div>
                  <div className='user-stats'>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={showMembers}
                      onClose={handleCloseMembers}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <CommunityMembers members={members}/>
                      </Box>
                    </Fade>
                    </Modal>
                  </div>
                  <div className='user-actions'>
                  <Tooltip title='Add User to Community'>
                    <button className='button' onClick={handleInviteUser}>
                      <PersonAddAlt />
                    </button>
                    </Tooltip>
                    <Tooltip title='Community Settings'>
                    <button className='button' onClick={handleOpen}>
                      <Settings />
                    </button>
                    </Tooltip>
                    <Tooltip title='More Options'>
                      <button className='button' onClick={(e) => handleMenuOpen(e)}>
                        <MoreVert />
                      </button>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem sx={{padding: '5px 12px', fontSize: '.9rem'}} onClick={() => handleMenuItemClick('leave')}>
                        <ExitToApp />
                        Exit Community
                        </MenuItem>
                    </Menu>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openConfirm}
                      onClose={handleCloseConfirm}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                    }}>
                      <Fade>
                        <ConfirmRequest
                        sendingAction={isExiting}
                        title={'Exit Community?'}
                        text={`Are you sure you want to leave ${community.commName}?`}
                        onAction={() => handleExitCommunity()} // Pass a function reference
                        onClose={handleCloseConfirm}
                        actionButton={'Exit Community'}
                        sendingActionButton={'Exiting...'}/>
                      </Fade>
                    </Modal>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                  <Fade in={open}>
                    <div>
                      <EditCommunity uuid={uuid} handleClose={handleClose}
                      community={community} fetchData={fetchData}
                      isAdminComm={isAdminComm} isUserAdmin={isUserAdmin}
                      walletStatus={walletStatus} isDemoComm={isDemoComm}
                      profile={profile}
                      />
                    </div>
                  </Fade>
                    </Modal>
                  </div>
                </div>
                
              </div>
              <div className='sticky-section-navigation'>
              <div className='nav-item' onClick={handleShowCommunityInfo}>
                <Info />
                Info
              </div>
              
              <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={showCommunityInfo}
                    onClose={handleCloseCommunityInfo}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <CommunityInfo community={community} uuid={uuid}
                      isUserAdmin={isUserAdmin} isAdminComm={isAdminComm}
                      fetchData={fetchData} members={members} handleCloseCommunityInfo={handleCloseCommunityInfo}/>
                      </Box>
                            </Fade>
                        </Modal>
                <div className='nav-item' onClick={handleShowLounges}>
                <Weekend />
                  RaiseMe
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={showLounges}
                    onClose={handleCloseLounges}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <RaiseMes uuid={uuid}/>
                      </Box>
                            </Fade>
                 </Modal>
                <div className='nav-item' onClick={handleOpenModal}>
                  <MonetizationOn />
                  Aid Lounge
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={handleCloseModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <AidLoungesList uuid={uuid}/>
                      </Box>
                    </Fade>
                 </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openChit}
                    onClose={handleCloseChit}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <ChitFundsList uuid={uuid} chitFunds={chitFunds}/>
                      </Box>
                            </Fade>
                 </Modal>
                <div className='nav-item' onClick={handleShowCommunityLounges}>
                <Weekend />
                  Social Lounge
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={showCommunityLounges}
                    onClose={handleCloseCommunityLounges}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                      <Box className="comm-popup-modal">
                      <CommunityLounges uuid={uuid}/>
                      </Box>
                            </Fade>
                </Modal>
                {walletStatus && (
                  <>
                  <div className='nav-item' onClick={handleOpenWallet}>
                  <MonetizationOn />
                    Wallet
                  </div>
                  <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={showWallet}
                  onClose={handleCloseWallet}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}>
                  <Fade>
                    <Box className="comm-popup-modal">
                    <CommunityWallet community={community} isUserAdmin={isUserAdmin}
                    isAdminComm={isAdminComm} profile={profile}/>
                    </Box>
                          </Fade>
              </Modal>
              </>
                )}
              </div>
              {showInviteUser && (
                <div className='modal-overlay'>
                  <InviteUser community={community} uuid={uuid} onCancel={handleCancelInviteUser}/>
                  {/* <AddMemberToCommunity commUuid={uuid} onCancel={handleCancelInviteUser} /> */}
                </div>
              )}
              <div className='post-section'>
                <div className='right-section-post'>
                  <PostShare uuid={uuid} />
                  <Stack direction={'row'} width={'100%'} spacing={2}>
                    <Box className='all-posts-section'>
                    <CommunityPosts uuid={uuid} />
                    </Box>
                    <Box className='post-info-member-section'>
                    <CommunityInfo community={community} uuid={uuid}
                      isUserAdmin={isUserAdmin} isAdminComm={isAdminComm}
                      fetchData={fetchData} members={members} handleCloseCommunityInfo={handleCloseCommunityInfo}/>
                    </Box>
                  </Stack>
                </div>
              </div>
              </div>
              </>
            ) : (
              <>
              <ErrorComponent message={message}/>
              </>
            )}
          </div>

          {loungeOpenModal && (
            <div className="modal-overlay">
              <CreateLounge onCancel={handleCloseLoungeModal}/>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default CommunityDetails
