import React, { useEffect, useState } from 'react'
import "./aidLounge.css"
import Header from '../../components/header/Header';
import UserProfile from '../../components/userProfile/UserProfile';
import { Avatar, Backdrop, Box, Button, Chip, Fade, Modal, Stack, Tooltip } from '@mui/material';
import { formatAmountWithCommas, handleError, LoadingFullScreen, NO_COVER_IMG } from '../../utils/routes';
import { AccountBalance, AddPhotoAlternate, MonetizationOn, Share } from '@mui/icons-material';
import DonationsList from '../../components/DonationsList/DonationsList';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useApiService } from '../../services/api-service';
import { useProfileService } from '../../services/profile-service';
import { useAuthService } from '../../services/auth-service';
import Donate from '../../components/Donate/Donate';
import SharePost from '../../components/Share/SharePost';
import EditAidLoungeBanner from '../../components/EditCommunity/EditAidLoungeBanner';
import WithdrawAid from '../../components/WithdrawAid/WithdrawAid';
import toast from 'react-hot-toast';
import ErrorComponent from '../../components/errors/ErrorComponent';


const AidLounge = () => {
  const [isCopied, setIsCopied] = useState(false);
  const url = window.location.href;
  const {id} = useParams();
  const apiService = useApiService();
  const [loading, setLoading] = useState(false);
  const [loungeData, setLoungeData] = useState();
  const [creatorDetails, setCreatorDetails] = useState();
  const [remainingDays, setRemainingDays] = useState(null);
  const [statements, setStatements] = useState([]);
  const profile = useProfileService();
  const {token} = useAuthService();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openEditBanner, setOpenEditBanner] = React.useState(false);
  const handleOpenEditBanner = () => setOpenEditBanner(true);
  const handleCloseEditBanner = () => setOpenEditBanner(false);
  const [openShare, setOpenShare] = React.useState(false);
  const handleOpenShare = () => setOpenShare(true);
  const handleCloseShare = () => setOpenShare(false);
  const [openWithdraw, setOpenWithdraw] = React.useState(false);
  const handleOpenWithdraw = () => setOpenWithdraw(true);
  const handleCloseWithdraw = () => setOpenWithdraw(false);
  const [loadingStatements, setLoadingStatements] = React.useState(false);
  
  const [withdrawalBalance, setWithdrawalBalance] = useState();
  const [loadingRelatedCauses, setLoadingRelatedCauses] = useState(false);
  const [relatedCauses, setRelatedCauses] = useState([]);
  const [balance, setBalance] = useState(null);
  const paymentMethods = JSON.parse(localStorage.getItem('payment_methods'));
    console.log('payments, ', paymentMethods)

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url)
    setIsCopied(true)
    toast.success("Link Copied to Clipboard")
  }

  const calculateRemainingDays = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    const differenceInMilliseconds = end - today;
    const days = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return days;
  };

  const getCreatorDetails = async (userId) => {
    try {
      const response = await apiService.profile.profileDetail({user_id: userId});
      if(response.status === 200 || response.status === 201){
        const data = response.data;
        setCreatorDetails(data)
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
      }else{
        toast.error('Network Error');
      }
    }
}
  const getBalance = async () => {
    try {
      const balanceRes = await apiService.payment.entityBalance({
        entity_id: id,
        payment_type: 'AidloungeDonation'
      })
      const balanceData = balanceRes.data;
      setBalance(balanceData)
      console.log('balance', balanceData)
    } catch (e) {
      if(e.response.status === 404) {
        console.log('here')
        setBalance(0);
      } else {
        handleError(e);
      }
      
    }
  }

  const getLoungeInfo = async () => {
    try {
      setLoading(true);
      const response = await apiService.aidlounge.info({aid_lounge_id : id});
      if(response.status === 200 || response.status === 201){
        const data = response.data;
        if(data.endDate){
        const remainingDays = calculateRemainingDays(data.endDate);
        setRemainingDays(remainingDays);
        }else{
          setRemainingDays(null)
        }
        setLoungeData(data)
        await getBalance();
        await getCreatorDetails(data.creatorId);
      }
    } catch (e) {
      if(e.response){
        toast.error(e.response.data["code "]);
    }
    } finally{
      setLoading(false);
    }
  }

  // Function to shuffle an array
  const shuffleArray = (array) => {
    const shuffledArray = [...array]; // Create a copy of the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Generate a random index
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements at i and j indices
    }
    return shuffledArray;
  };

  const getRelatedCauses = async () => {
    try {
      setLoadingRelatedCauses(true);
      const response = await apiService.aidlounge.allLounges();
      const shuffledData = shuffleArray(response.data); // Shuffle the data array
      setRelatedCauses(shuffledData); // Set the shuffled array in the state
    } catch (e) {
      
    } finally{
      setLoadingRelatedCauses(false);
    }
  }

  const getProfileById = async (credentials) => {
    try {
      const { data } = await apiService.profile.profileDetail(credentials);
      return data;
    } catch (error) {
      handleError(error);
      throw new Error('Invalid credentials or server error.');
    }
  };
  
  

  const getStatements = async () => {
    try {
      setLoadingStatements(true);
      const response = await apiService.payment.transactionByEntityIdAndPaymentType({entity_id: id, payment_type: 'AidloungeDonation'});
      const data = response.data;
      // Get unique user IDs from posts, excluding -1
    const uniqueUserIds = Array.from(
      new Set(data.map((transaction) => transaction.user_id))
    ).filter((userId) => userId !== -1);

    // Fetch profile details for unique users
    const profilePromises = uniqueUserIds.map((userId) =>
      getProfileById({ user_id: userId })
    );
    const profiles = await Promise.all(profilePromises);

    // Create a map of user_id to profile details
    const profileMap = Object.fromEntries(
      profiles.map((profile, index) => [uniqueUserIds[index], profile])
    );

    // Attach profile details to each post
    const statements = data.map((transaction) => ({
      ...transaction,
      profile:
        transaction.user_id === -1
          ? {
              bio: "Anonymous",
              birthDate: "",
              city: "",
              country: "",
              createdAt: "",
              firstName: "Unregistered User",
              gender: "",
              id: -1,
              lastName: "",
              photoUrl: "",
              updatedAt: "",
              userId: -1,
              userType: "Anonymous",
            }
          : profileMap[transaction.user_id],
    }));
    console.log('profile', statements)
      setStatements(statements);
    } catch (e) {
      
    } finally{
      setLoadingStatements(false);
    }
  }

  useEffect(() => {
    getLoungeInfo();
    getStatements();
    
    getRelatedCauses();   
  }, [])
  return (
    <>
        {loading ? (
          <>
          {LoadingFullScreen()}
          </>
        ) : (
          <>
            <Header/>
            <div className={token ? 'aidLoungeWrapper' : 'loggedOutaidLoungeWrapper'}>
              {token && (
                <div className='community-sidebar'>
                <UserProfile/>
              </div>
              )}
              <>
              {loungeData && creatorDetails && balance !== null ? (
                <>
                {token ? (
                    <>
                <Box className={token ? 'aidLoungeContainer' : 'loggedOutaidLoungeContainer'}>
                  <Box className='aidLoungeDetails'>
                    <Box>
                      <h2 className='aidLoungeTitle'>{loungeData.name}</h2>
                    </Box>
                    <Box>
                      <Link target='_blank' to={loungeData.aidLoungeType === 'COMMUNITY' ? `/community/${loungeData.communityId}` : `/`}>
                      <Chip icon={<MonetizationOn/>} label={loungeData.aidLoungeType} size='small' sx={{backgroundColor: "light.main"}}/>
                      </Link>
                    </Box>
                    <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <>
                        <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                      <Avatar src={creatorDetails.photoUrl} alt={`${creatorDetails.firstName} ${creatorDetails.lastName}`}/>
                      <Box>
                        <p className='alignLeft'>Created By:</p>
                        <p className='alignLeft'>{creatorDetails.firstName} {creatorDetails.lastName}</p>
                      </Box>
                      </Box>
                      </>
                      <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center'}}>
                      <Tooltip title='Share Link'>
                        <div className='add-circle-icon'>
                      <Share sx={{cursor: 'pointer', color: 'light.main'}} onClick={handleOpenShare}/>
                      </div>
                      </Tooltip>
                      <p className='purple-text'>Share</p>
                      </Box>
                      {profile.profile.userId === loungeData.creatorId && (
                        <>
                      <Box sx={{display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center'}}>
                      <Tooltip title='Withdraw Funds'>
                        <div className='add-circle-icon'>
                      <AccountBalance sx={{cursor: 'pointer', color: 'light.main'}} onClick={handleOpenWithdraw}/>
                      </div>
                      </Tooltip>
                      <p className='purple-text'>Withdraw</p>
                      </Box>
                      <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={openWithdraw}
                      onClose={handleCloseWithdraw}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}>
                        <Fade>
                      <WithdrawAid id={id} loungeData={loungeData} onCancel={handleCloseWithdraw} withdrawalBalance={withdrawalBalance}/>
                              </Fade>
                          </Modal>
                          </>
                      )}
                      </Box>
                      <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openShare}
                    onClose={handleCloseShare}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <SharePost onCancel={handleCloseShare} url={url} handleCopyLink={handleCopyLink} isCopied={isCopied}/>
                            </Fade>
                        </Modal>
                        
                    </Box>
                    <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: '400px', // Adjust the height as needed
                      overflow: 'hidden',
                  }}
                    >
                      {profile.profile.userId === loungeData.creatorId && (
                        <>
                        <Tooltip title='Edit Aid Lounge Banner'>
                      <div className='add-commbanner-icon' onClick={handleOpenEditBanner}>
                              <AddPhotoAlternate
                                  sx={{
                                      color: 'white'
                                  }}
                              />
                       </div>
                       </Tooltip>
                        <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openEditBanner}
                        onClose={handleCloseEditBanner}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}>
                        <Fade>
                            <EditAidLoungeBanner loungeData={loungeData} handleClose={handleCloseEditBanner} getLoungeInfo={getLoungeInfo}/>
                                </Fade>
                        </Modal>
                       </>
                      )}
                      <img className='aidLoungeImage' src={loungeData.banner || NO_COVER_IMG} alt={loungeData.name} />
                    </Box>
                    <Box className='aidLoungeDescription'>
                        <ReactReadMoreReadLess
                        charLimit={200}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                        >
                          {loungeData.descr}
                        </ReactReadMoreReadLess>
                    </Box>
                  </Box>
                  <Box className='aidLoungeRight'>
                  <Box className='aidLoungeDonateContainer'>
                    <Stack direction={'row'} spacing={1}>
                    <h2 style={{ color: '#a06ccb' }}>${formatAmountWithCommas(balance.availableBalance)}</h2>
                    <p>CAD raised of ${formatAmountWithCommas(loungeData.targetAmt)} goal</p>
                    </Stack>
                    <progress className='aid-lounge-progress' value={balance.availableBalance || 0} max={loungeData.targetAmt}/>
                    <Box className='aidLoungeDonationDetails'>
                      {loungeData.endDate ? (
                        <>
                          <Box className='aidLoungeDonationDetail'>
                        {remainingDays <= 0 ? (
                            <>
                            <h2>Aid Lounge</h2>
                            <p>Finished</p>
                            </>
                        ): (
                            <>
                            <h2>{remainingDays}</h2>
                            <p className='alignLeft'>
                                {remainingDays <= 1 ? 'Day' : 'Days'} Remaining
                            </p>
                            </>
                        )}
                      </Box>
                        </>
                      ): (
                        <>
                        <Box className='aidLoungeDonationDetail'>
                            <h2>Aid Lounge</h2>
                            <p className='alignLeft'>End date not specified</p>
                      </Box>
                        </>
                      )}
                      {statements ? (
                        <Box className='aidLoungeDonationDetail'>
                        <h2>{statements.length}</h2>
                        <p className='alignLeft'>{statements.length <= 1 ? 'Donation' : 'Donations'}</p>
                      </Box>
                      ) : (
                        <Box className='aidLoungeDonationDetail'>
                          <p className='alignLeft'>Error loading donations</p>
                          </Box>
                      )}
                    </Box>
                    <Box sx={{width: '100%'}}>
                      <DonationsList loungeData={loungeData} statements={statements} loadingStatements={loadingStatements}/>
                    </Box>
                    {loungeData.endDate ? (
                      <Button onClick={handleOpen} disabled={remainingDays<1} className='button' sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}>Donate Now</Button>
                    ) : (
                      <Button onClick={handleOpen} className='button' sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}>Donate Now</Button>
                    )}
                    
                    <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <Donate loungeData={loungeData} onCancel={handleClose} creatorDetails={creatorDetails} getStatements={getStatements}/>
                            </Fade>
                    </Modal>
                  </Box>
                  </Box>
                </Box>
                </>
              ) : (
                <>
                <Box className={token ? 'aidLoungeContainer' : 'loggedOutaidLoungeContainer'}>
                  <Box className='aidLoungeDetails'>
                    <Box>
                      <h2 className='aidLoungeTitle'>{loungeData.name}</h2>
                    </Box>
                    <Box>
                      <Chip icon={<MonetizationOn/>} label={loungeData.aidLoungeType} size='small' sx={{backgroundColor: "light.pending"}}/>
                    </Box>
                    <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                        <>
                      <Avatar src={creatorDetails.photoUrl} alt={`${creatorDetails.firstName} ${creatorDetails.lastName}`}/>
                      <Box>
                        <p className='alignLeft'>Created By:</p>
                        <p className='alignLeft'>{creatorDetails.firstName} {creatorDetails.lastName}</p>
                      </Box>
                      </>
                      <Tooltip title='Share Link'>
                      <Share sx={{cursor: 'pointer'}} onClick={handleOpenShare}/>
                      </Tooltip>
                      <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openShare}
                    onClose={handleCloseShare}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <SharePost onCancel={handleCloseShare} url={url} handleCopyLink={handleCopyLink} isCopied={isCopied}/>
                            </Fade>
                        </Modal>
                    </Box>
                    <Box>
                      <img className='aidLoungeImage' src={loungeData.banner || NO_COVER_IMG} alt={loungeData.name} />
                    </Box>
                    <Box className='aidLoungeDescription'>
                      <ReactReadMoreReadLess
                      charLimit={200}
                      readMoreText={"Read more ▼"}
                      readLessText={"Read less ▲"}
                      >
                        {loungeData.descr}
                      </ReactReadMoreReadLess>
                    </Box>
                  </Box>
                  <Box className='aidLoungeRight'>
                  <Box className='aidLoungeDonateContainer'>
                    <Stack direction={'row'} spacing={1}>
                    <h2 style={{ color: '#a06ccb' }}>${formatAmountWithCommas(balance.availableBalance)}</h2>
                    <p>CAD raised of ${formatAmountWithCommas(loungeData.targetAmt)} goal</p>
                    </Stack>
                    <progress className='aid-lounge-progress' value={balance.availableBalance || 0} max={loungeData.targetAmt}/>
                    <Box className='aidLoungeDonationDetails'>
                      {loungeData.endDate ? (
                        <>
                          <Box className='aidLoungeDonationDetail'>
                        {remainingDays <= 0 ? (
                            <>
                            <h2>Aid Lounge</h2>
                            <p>Finished</p>
                            </>
                        ): (
                            <>
                            <h2>{remainingDays}</h2>
                            <p className='alignLeft'>
                                {remainingDays <= 1 ? 'Day' : 'Days'} Remaining
                            </p>
                            </>
                        )}
                      </Box>
                        </>
                      ): (
                        <>
                        <Box className='aidLoungeDonationDetail'>
                            <h2>Aid Lounge</h2>
                            <p className='alignLeft'>End date not specified</p>
                      </Box>
                        </>
                      )}
                      {statements ? (
                        <Box className='aidLoungeDonationDetail'>
                        <h2>{statements.length}</h2>
                        <p className='alignLeft'>{statements.length <= 1 ? 'Donation' : 'Donations'}</p>
                      </Box>
                      ) : (
                        <Box className='aidLoungeDonationDetail'>
                          <p className='alignLeft'>Error loading donations</p>
                          </Box>
                      )}
                    </Box>
                    <Box sx={{width: '100%'}}>
                      <DonationsList loungeData={loungeData} statements={statements} loadingStatements={loadingStatements}/>
                    </Box>
                    {loungeData.endDate ? (
                      <Button onClick={handleOpen} disabled={remainingDays<1} className='button' sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}>Donate Now</Button>
                    ) : (
                      <Button onClick={handleOpen} className='button' sx={{backgroundColor: 'light.main', color: 'white', width: '100%'}}>Donate Now</Button>
                    )}
                    
                    <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}>
                    <Fade>
                    <Donate loungeData={loungeData} onCancel={handleClose} creatorDetails={creatorDetails} getStatements={getStatements}/>
                            </Fade>
                    </Modal>
                  </Box>
                  {/* <Box className='aidLoungeDonateContainer'>
                    {loadingRelatedCauses ? (
                      <>
                      <Box sx={{ width: '100%' }}>
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                      </Box>
                    </>
                    ) : (
                      <>
                      <RelatedAidLounges relatedCauses={relatedCauses}/>
                      </>
                    )}
                  </Box> */}
                  </Box>
                </Box>
                </>
              )}
                </>
              ) : (
                <>
                <ErrorComponent message={`Error Loadind Aid Lounge`}/>
                </>
              )}
              </>
            </div>
            </>
            )}
        </>
  )
}

export default AidLounge;