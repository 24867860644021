import React, { createContext, useContext, useMemo } from 'react'

import { useAuthService } from './auth-service'
import useAxios from '../hooks/use-axios'

/**
 * Make API Client.
 *
 * @param {import('axios').AxiosInstance} client
 */
const makeApiClient = client => ({
  auth: {
    logout: () => client.post('/auth/logout'),
    profile: () => client.get('/user-profile'),
    userProfile: () => client.get('/user/profile'),
    login: values => client.post('/auth/login', values),
    refreshToken: values => client.post('/auth/refresh-token', values),
    forgotPassword: values => client.post('/auth/forgot-password', values),
    resetPassword: values => client.post('/auth/reset-password', values),
    register: values => client.post('/register', values),
    updateProfile: values => client.put('/profile/update', values),
    createProfile: values => client.post('/profile/create', values),
    confirmEmail: values => client.put('/auth/confirm-email', values),
    getUserInfo: values => client.post('/auth/userInfo', values),
    resendEmail: values => client.post('/auth/resend-email-token', values),
    verifyEmail: values => client.post('/verify/email', values),
    userInfo: values => client.post('/auth/userInfo', values),
    resendOtp: values => client.post('/auth/devices/resend-otp', values)
  },
  profile: {
    profileUpload: values => client.post('/profile/upload', values),
    profileDetails: values => client.post('/user-details', values),
    profileDetail: values => client.post('/user-detail', values),
    search: (values) => client.get(`/profile/search?search_term=${encodeURIComponent(values)}`)
  },
  raiseme: {
    addRaise: values => client.post('/v1/raiseme/create', values),
    addMember: values => client.post('/v1/raiseme/member/create', values),
    createSubscriptions: values => client.post('/raiseme/subscriptions/create/', values),
    allRaiseMe: values => client.post('/v1/raiseme/all', values),
    createKYC: values => client.post('/v1/raiseme/kyc/create', values),
    createAccount: values => client.post('/v1/raiseme/account/create', values),
    getKYC: () => client.get('/v1/raiseme/kyc'),
    getMembers: values => client.post('/v1/raiseme/members', values),
    getAccount: () => client.get('/v1/raiseme/account/info'),
    getInfo: values => client.post('/v1/raiseme/user-raiseme', values),
    verifyKYC: values => client.post('/v1/raiseme/kyc-verification', values),
    getKYCByLocation: (values) => client.get(`/v1/raiseme/kyc/services?location=${values}`),
    getAllRaiseMe: values => client.post('/v1/raiseme/user-raiseme', values),
    getRaiseMeInfo: values => client.post('/v1/raiseme/info', values),
    getTiers: () => client.get('/raiseme/products/'),
    getTransactionByID: values => client.post('/v1/raiseme/transaction/by-userid', values),
    getAllTransactions: () => client.get('/v1/raiseme/transaction/all'),
    getTransactionsByRaiseMe: values => client.post('/v1/raiseme/transaction/by-raiseme', values),
    getPositions: values => client.post('/v1/raiseme/positions', values),
    getPositionsKickOff: values => client.post('/v1/raiseme/positions/kickoff', values),
    submitPosition: values => client.post('/v1/raiseme/submit-positions', values),
    submitSwapKickOff: values => client.post('/v1/raiseme/submit-swap/kickoff', values),
    submitSwap: values => client.post('/v1/raiseme/submit-swap', values),
    requestToPay: values => client.post('/v1/raiseme/transaction/request_to_pay', values),
    debitInfo: values => client.post('/v1/raiseme/transaction/debit_info', values),
    fetchbyRaisemeType: values => client.post('/v1/raiseme/raiseme_type', values)
  },
  community: {
    createCommunity: values => client.post('/v1/community/create', values),
    uploadBanner: values => client.post('/v1/community/banner/upload', values),
    info: values => client.post('/v1/community/info', values),
    createMember: values => client.post('/v1/community/member/create', values),
    membership: () => client.post('/v1/community/membership'),
    getCommunityInfo: values => client.post('/v1/community/info', values),
    getCommunityMembers: values => client.post('/v1/community/members', values),
    retrieveLocation: values => client.post('/v1/community/retrieve-location', values),
    createPost: values => client.post('/v1/community/post/create', values),
    createComment: values => client.put('/v1/community/post/update', values),
    allPosts: (values, page, pageSize) => client.post(`/v1/community/posts/all?page_size=${pageSize}&page=${page}`, values),
    getPost: values => client.post('/v1/community/posts/by-post-uuid', values),
    allComments: values => client.post('/v1/community/post/comments', values),
    deletePosts: (values) => client.delete(`/v1/community/post/delete?post_id=${values}`),
    createReaction: values => client.post('/v1/community/post-reaction/create', values),
    deleteReaction: (values) => client.delete(`/v1/community/post-reaction/delete?post_id=${values}`),
    reactionStatus: (values) => client.post('/v1/community/post-reaction/liked', values),
    getPendingEndorsements: () => client.post('/v1/notification/endorsement/pending'),
    assignRole: (values) => client.post('/v1/community/member/assign_role', values),
    removeMember: values => client.post('/v1/community/member/remove', values),
    approveMember: values => client.post('/v1/notification/admin_approve', values),
    walletContribution: values => client.post('/v1/raiseme/transaction/comm_contribution', values),
    walletWithdrawal: values => client.post('/v1/raiseme/transaction/community/withdrawal', values),
    getBalance: values => client.post('/v1/raiseme/transaction/community_contribution/balance', values),
    updateWalletStatus: values => client.post('/v1/community/update_wallet_status', values),
    updateBalanceStatus: values => client.post('/v1/community/update_comm_balance_status', values),
    getVotes: values => client.post('/v1/notification/adminmanage_community/votes', values),
    getCommNameVotesAdmin: values => client.post('/v1/notification/adminmanage_community/name_update/votes', values),
    voteCommNameAdmin: values => client.post('/v1/notification/adminmanage_community/name_update/add_vote', values),
    voteCommNameDemocratized: values => client.post('/v1/notification/democratized_community/name/add_vote', values),
    getCommNameVotesDemocratized: values => client.post('/v1/notification/democratized_community/name_update/votes', values),
    voteWithdrawal: values => client.post('/v1/notification/adminmanage_community/withdrawal/add_vote', values),
    democratizedVoteWithdrawal: values => client.post('/v1/notification/democratized_community/withdrawal/add_vote', values),
    getDemocratizedVotes: values => client.post('/v1/notification/democratized_community/votes', values),
    getWalletHistory: values => client.post('/v1/raiseme/transaction/community/statement', values),
    getMigrateVotes: values => client.post('/v1/notification/community/upgrade/votes', values),
    reportPost: values => client.post('/v1/community/post/report', values),
    uploadDocument: values => client.post('/v1/media/upload', values),
    uploadVideo: values => client.post('/v1/media/video_upload', values),
    leaveCommunity: values => client.post('/v1/community/member/exit', values),
    updateCommunityName: values => client.put('/v1/community/update_comm_name', values),
    generateLink: values => client.post('/v1/community/generate_link', values),
    linkInfo: values => client.post('/v1/community/link_info', values),
    useLink: values => client.post('/v1/community/use_link', values)
  },
  notification: {
    invitationEmail: values => client.post('/v1/notification/invitation-email', values),
    push: values => client.post('/v1/notification/push', values),
    verify: values => client.put('/v1/notification/push/verify', values),
    received: values => client.post('/v1/notification/pull', values),
    updateStatus: values => client.put('/v1/notification/update-status', values),
    addEndorsement: values => client.post('/v1/notification/endorsement/add', values),
    approveAdminStatus: values => client.post('/v1/notification/admin_approve', values),
    upgradeCommVote: values => client.post('/v1/notification/community/upgrade/add_vote', values)
  },
  user: {
    subscriptionUpdate: values => client.put('/user/subscriptions/update/', values),
    subscriptionCreate: values => client.post('/user/subscriptions/create/', values),
    getDetailsById: values => client.post('/user-details', values),
    blockUser: values => client.post('/users/block_user', values),
    unBlockUser: values => client.post('/users/unblock_user', values),
    blockedUsers: () => client.post('/users/blocks'),
  },
  lounge: {
    createLounge: values => client.post('/v1/lounge/create', values),
    createMember: values => client.post('/v1/lounge/member/create', values),
    getInfo: values => client.post('/v1/lounge/info', values),
    getLounges: values => client.post('/v1/lounge/get-lounges', values),
    createPost: values => client.post('/v1/lounge/post/create', values),
    allPosts: values => client.post('/v1/lounge/posts/all', values),
    getPost: values => client.post('/v1/lounge/posts/by-post-uuid', values),
    createReaction: values => client.post('/v1/lounge/post-reaction/create', values),
    deleteReaction: (values) => client.delete(`/v1/lounge/post-reaction/delete?post_id=${values}`),
    reactionStatus: (values) => client.post('/v1/lounge/post-reaction/liked', values),
    allComments: values => client.post('/v1/lounge/post/comments', values),
    deletePosts: (values) => client.delete(`/v1/lounge/post/delete?post_id=${values}`),
    getMembers: values => client.post('/v1/lounge/members', values),
    reportPost: values => client.post('/v1/lounge/post/report', values)
  },
  mfa: {
    setup: values => client.post('/user/mfa-setup', values),
    verify: values => client.put('/user/mfa-verify', values),
    status: values => client.post('/user/mfa-status', values),
    toggleStatus: values => client.post('/auth/status/toggle', values)
  },
  subscription: {
    upgrade: values => client.post('/subscription/upgrade', values),
    status: () => client.get('/subscription/status')
  },
  aidlounge: {
    create: values => client.post('/v1/aidlounge/create', values),
    info: values => client.post('/v1/aidlounge/aid_lounge_info', values),
    allLounges: () => client.get('/v1/aidlounge/all'),
    allLoungesByCreator: () => client.get('/v1/aidlounge/by_creator_id'),
    allLoungesByCommunity: values => client.post('/v1/aidlounge/by_comm_uuid', values),
    uploadBanner: values => client.post('/v1/aidlounge/banner/upload', values),
    makeDonation: values => client.post('/v1/payment/process_payment', values),
    getStatement: values => client.post('/v1/raiseme/transaction/aidlounge/statement', values),
    makeWithdrawal: values => client.post('/v1/raiseme/transaction/withdrawal', values),
    getBalance: values => client.post('/v1/raiseme/transaction/aidlounge/balance', values),
    vote: values => client.post('/v1/notification/aidlounge/withdrawal/vote', values),
    votes: values => client.post('/v1/notification/aidlounge/votes', values)
  },
  payment: {
    paymentChannels: () => client.get('/v1/payment/payment_channels'),
    paymentType: () => client.get('/v1/payment/payment_types'),
    transactionTypes: () => client.get('/v1/payment/transaction_types'),
    processPayment: values => client.post('/v1/payment/process_payment', values),
    transactionByEntityIdAndPaymentType: values => client.post('/v1/payment/transactions_by_entity_id_and_payment_type/', values),
    entityBalance: values => client.post('/v1/payment/entity_balance', values),
  }
})

/**
 * API Service React Context.
 */
const ApiServiceContext = createContext(makeApiClient())

/**
 * API Service React Context Provider.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function ApiServiceProvider ({ children }) {
  const { token } = useAuthService()
  const client = useAxios(token)
  const methods = useMemo(() => makeApiClient(client), [client])

  return (
    <ApiServiceContext.Provider value={methods}>
      {children}
    </ApiServiceContext.Provider>
  )
}

/**
 * Use API Service React Context.
 */
export function useApiService () {
  return useContext(ApiServiceContext)
}
