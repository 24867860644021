import { Avatar, Backdrop, Box, Fade, Modal, Skeleton, Stack } from '@mui/material'
import React from 'react'
import './donationslist.css'
import { getTimeDifference } from '../../utils/routes'
import ViewDonationStatements from './ViewDonationStatements'
import { useAuthService } from '../../services/auth-service'

const DonationsList = ({ statements, loadingStatements, loungeData }) => {
  const [open, setOpen] = React.useState(false);
  const {token} = useAuthService();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
      <h3 style={{ marginBottom: '0.5rem' }}>Recent Donations</h3>
      {token && (
        <p className='purpleHover' style={{cursor: 'pointer', fontWeight: 'bold'}} onClick={handleOpen}>View all</p>
      )}
      <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                timeout: 500,
                                },
                            }}>
                            <Fade>
                                <Box className="statement-popup-modal">
                                <ViewDonationStatements walletHistory={statements} handleClose={handleClose} loungeData={loungeData}/>
                                </Box>
                            </Fade>
                            </Modal>
      </Stack>
      {loadingStatements ? (
        <>
        <Box sx={{ width: '100%' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
        </>
      ):(
        <>
        <Box className='donationsListWrapper'>
        {statements.length === 0 ? (
          <p className='alignLeft'>No donations made yet</p>
        ) : (
          statements.map((data, index) => (
            <Stack key={index} direction={'row'} spacing={2} display={'flex'} sx={{ width: '100%' }}>
              <Avatar src={data.profile.photoUrl} alt={data.profile.firstName} />
              <Box className='donationsList'>
                <Stack direction={'column'} display={'flex'} alignItems={'flex-start'}>
                  <h3 className='blackText'>
                    {data.anonymous ? 'Anonymous' : `${data.profile.firstName} ${data.profile.lastName}`}
                  </h3>
                  <p>${data.transaction_amount}</p>
                </Stack>
                <p>{getTimeDifference(data.transaction_date)}</p>
              </Box>
            </Stack>
          ))
        )}
      </Box>
        </>
      )}
      
    </>
  )
}

export default DonationsList
