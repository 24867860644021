import React from 'react';
import { Divider, Avatar } from '@mui/material';
import "./UserProfile.css";
import Accordions from '../accordion/Accordions';
import { useProfileService } from '../../services/profile-service';
import { NO_COVER_IMG } from '../../utils/routes';
import { Place } from '@mui/icons-material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const UserProfile = () => {
  const { profile, profileLoaded } = useProfileService();

  return (
    <div className='leftSideWrapper'>
        <div className="userProfileWrapper">
          {profile && (
            <>
            <div className="profileImages">
            <img className='imgBanner' src={NO_COVER_IMG} alt=''/>
            
            <Avatar className='imgProfile' alt={`${profile.firstName} ${profile.lastName}`} src={profile?.photoUrl} />
          </div>
          <div className="profileName">
            <Link to={`/profile/${profile.userId}`}>
            <span className="name">{profileLoaded && profile ? <b>{profile.firstName} {profile.lastName}</b> : ''}</span>
            </Link>
            <span className="profile-location">
            <Place/> {profile?.city}, {profile?.country}
            </span>
          </div>
          <Divider style={{margin:"auto"}} />
          </>
          )}
          <Accordions />
        </div>
    </div>
    
  )
}

export default UserProfile;