import React from 'react'
import { AddCardOutlined, SavingsOutlined } from '@mui/icons-material';
import './raisemewalletoverview.css'
import { formatCurrency } from '../../utils/routes';
const RaiseMeWalletOverview = ({totalCreditedAmount, totalDebitedAmount}) => {
  return (
    <div className="raisemewallet-overview">
                <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="raisemewallet-overview-top">
                    <div className="raisemewallet-overview-title">
                    <SavingsOutlined/>
                    <h2>Total Received</h2>
                    </div>
                    </div>
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span'>{formatCurrency(totalDebitedAmount)}</span> </p>
                    </div>
                  </div>

                </div>
                <div className="raisemewallet-container">
                  <div className="raisemewallet-info">
                    <div className="raisemewallet-overview-top">
                    <div className="raisemewallet-overview-title">
                    <AddCardOutlined/>
                    <h2>Total Contribution</h2>
                    </div>
                    </div>
                    <div className="raisemewallet-overview-bottom">
                    <p><span className='arrow-span green'>{formatCurrency(totalCreditedAmount)}</span> </p>
                    </div>
                  </div>

                </div>
                </div>
  )
}

export default RaiseMeWalletOverview